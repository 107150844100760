<template>
  <div class="">
    <div class="h-48 fill_all w-full fixed bg-gray2 opacity-70 transition filter blur" v-if="!ShowErr"></div>
    <div class="flex justify-center relative z-50 pt-14 fill_all" v-if="!ShowErr">
      <div class="card bg-white rounded-xl p-4 m-4 mt-0 pt-0 sm:w-auto lg:w-11/12 xl:w-10/12 2xl:w-9/12">
        <div class="relative">
          <div class="card top_primary_card bg-primary font-bold text-white p-4 rounded-xl absolute -top-8 w-full heading-2">
            Quotation
          </div>
        </div>
        <div class="mt-12">
          <div class="" v-if="quoteDetails !== null">
              <div class="">
                <div class="  mb-4 grid grid-cols-12">
                  <div class="col-span-6">
                    <p class="text-primary heading-2 font-bold">{{OrgDetail.orgName}}</p>
                    <span class="flex items-center">
                      <p class="text-gray4">Tax No:</p>
                      <p class="text-text2 pl-2">{{OrgDetail.taxNumber}}</p>
                    </span>
                    <p class="text-text2">
                      {{OrgDetail.addressLine1 !== '' ? OrgDetail.addressLine1 + ',' : ''}} {{OrgDetail.addressLine2 !== '' ? OrgDetail.addressLine2 + ',' : ''}}
                    </p>
                    <p class="text-text2">
                      {{OrgDetail.city !== '' ? OrgDetail.city + ',' : ''}} {{OrgDetail.state}} {{ OrgDetail.zip }}
                    </p>
                    <p v-if="OrgDetail.country !== ''" class="text-text2">
                      {{OrgDetail.country}}
                    </p>
                  </div>
                  <div class="col-span-6 text-right" v-if="screenWidth < 780">
                    <p class="text-text2 heading-3 font-bold mb-1">#{{quoteDetails.quotationNumber}}</p>
                    <span class="flex items-center justify-end">
                      <p class="text-gray4">status:</p>
                      <p class="text-text2 pl-2" :style="'color:' + quoteDetails.statusColor">{{quoteDetails.statusName}}</p>
                    </span>
                    <button class=" bg-primary text-white p-1 heading-7 mt-2 cursor-pointer" style="letter-spacing: 0.4px;" @click="downloadInvoice()">DOWNLOAD</button>
                  </div>
                  <div class="col-span-6 text-right" v-if="screenWidth >= 780">
                    <p class="text-text2 heading-3 font-bold mb-1">#{{quoteDetails.quotationNumber}}</p>
                    <p class="text-text2 mb-1">
                      As of {{quoteDetails.quoteDate | dateFilterInvoice}}, Quotation
                    status is <span class="heading-5 font-bold" :style="'color:' + quoteDetails.statusColor">{{quoteDetails.statusName}}</span>
                    </p>
                    <Button class="mb-1" :isSqure="true" :btnSize="'small'" style="letter-spacing: 1.4px;" :textColor="'white'" :btnColor="'primary'" :btnText="'DOWNLOAD'" @buttonAction="downloadInvoice()"/>
                  </div>
                </div>
                <div>
                  <div class="divider"></div>
                    <div class=" text-gray3 my-2 grid grid-cols-12 items-center">
                      <div class="col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6">
                        <p class="text-text2 heading-2 font-bold">{{quoteDetails.companyName}}</p>
                          <div class="">
                            <span class="flex items-center" v-if="quoteDetails.customerFirstName !== '' || quoteDetails.customerLastName !== ''">
                              <p class="w-36">Customer Name:</p>
                              <p class="text-gray4 pl-2">{{quoteDetails.customerFirstName}} {{quoteDetails.customerLastName}}</p>
                            </span>
                            <span class="flex items-center">
                              <p class="w-36">Email:</p>
                              <p class="text-gray4 pl-2">{{quoteDetails.customerEmailAddress}}</p>
                            </span>
                            <span class="flex items-center">
                              <p class="w-36">Phone #:</p>
                              <p class="text-gray4 pl-2">{{quoteDetails.customerPhoneNumber | phone}}</p>
                            </span>
                          </div>
                      </div>
                      <div class="col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 flex justify-start md:justify-start xl:justify-end lg:justify-end">
                        <div class="">
                          <span class="flex items-center justify-end">
                            <p>Quotation Date:</p>
                            <p class="text-text2 pl-2">{{quoteDetails.quoteDate | dateFilterInvoiceRegularFormate}}</p>
                          </span>
                          <span class="flex items-center text-error justify-end">
                            <p>Due Date:</p>
                            <p class="pl-2">{{quoteDetails.expiryDate | dateFilterInvoiceRegularFormate}}</p>
                          </span>
                        </div>
                      </div>
                    </div>
                </div>
                <div>
                  <div class="divider mb-2"></div>
                  <div class="" v-if="screenWidth >= 1180">
                    <div class="bg-primary text-white flex table_containder card rounded p-2">
                      <div class="hash_col  font-bold heading-4 ">#</div>
                      <div class="name_col font-bold heading-4 ">Name</div>
                      <div class="desc_col font-bold heading-4 ">Description</div>
                      <div class="rate_col font-bold heading-4 text-right">Rate</div>
                      <div class="qty_col font-bold heading-4 text-right">Qty</div>
                      <div class="tax_col font-bold heading-4 text-right">Unit</div>
                      <div class="amount_col font-bold heading-4 text-right">Amount</div>
                    </div>
                    <div class=" flex table_containder" style="flex-flow:wrap;">
                      <div class="flex p-2  rounded w-full my-1 card hover:bg-gray-100" v-for="(name, index) in quoteDetails.itemList" :key="index">
                        <div class="hash_col text-gray4 heading-6">
                          {{index + 1}}
                        </div>
                        <div class="name_col text-text2 font-semibold">
                          <span v-if="name.itemName !== ''">{{ name.itemName}}</span>
                          <span v-else>-</span>
                        </div>
                        <div class="desc_col text-gray4 heading-6">
                          <span v-if="name.itemDescription !== ''">{{ name.itemDescription}}</span>
                          <span v-else>-</span>
                        </div>
                        <div class="rate_col text-gray4 heading-6 text-right" >
                          <span v-if="name.itemPrice !== ''">{{ name.itemPrice |amountFormaterWithToFix}}</span>
                          <span v-else>-</span>
                        </div>
                        <div class="qty_col text-gray4 heading-6 text-right">
                          <span v-if="name.itemQty !== ''">{{ name.itemQty}}</span>
                          <span v-else>-</span>
                        </div>
                        <div class="tax_col text-gray4 heading-6 text-right">
                          <span v-if="name.itemUnit !== ''">{{ name.itemUnit}}</span>
                          <span v-else>-</span>
                        </div>
                        <div class="amount_col text-gray4 heading-6 text-right">
                          <span v-if="name.itemAmount !== ''">{{ name.itemAmount |amountFormaterWithToFix}}</span>
                          <span v-else>-</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="" v-if="screenWidth < 1180">
                    <div class="table_containder bg-primary text-white flex table_containder p-2 items-center justify-between rounded card">
                      <div class="hash_col font-bold heading-4 ">Name</div>
                      <div class="hash_col font-bold heading-4 ">Amount</div>
                    </div>
                    <div class=" flex table_containder" style="flex-flow:wrap;">
                      <div class="p-2 w-full rounded my-1 card" v-for="(name, index) in quoteDetails.itemList" :key="index">
                        <div class="my-1 flex items-center justify-between" >
                          <div class="heading-6 text-text2 w-80 font-semibold">
                            <div>{{name.itemName === '' ? '-' : name.itemName}}</div>
                          </div>
                          <div class="heading-6 text-gray4">
                            <div>{{name.itemAmount === '' ? '-' : name.itemAmount | amountFormaterWithToFix}}</div>
                          </div>
                        </div>
                        <div class="flex my-1 items-center justify-between" >
                          <div class="flex ">
                            <div class="flex w-40">
                              <span class="flex items-center">
                                <p>Rate:</p>
                                <p class="text-text2 pl-2" v-if="name.itemPrice !== ''">{{name.itemPrice | amountFormaterWithToFix}}</p>
                                <p class="text-text2 pl-2" v-else>-</p>
                              </span>
                              <div class="flex pl-6 w-40">
                                <span class="flex items-center">
                                  <p>Qty:</p>
                                  <p class="text-text2 pl-2">{{name.itemQty}}</p>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="heading-6 text-gray4">
                            {{name.itemDescription}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                   <div class="grid grid-cols-12">
                    <div class=" box lg:col-start-5 2xl:col-start-7 xl:col-start-7  lg:col-span-8  2xl:col-span-6 xl:col-span-6 col-span-12 flex justify-end items-baseline mt-1">
                      <p class="detail_col text-right mr-4 text-text2 font-semibold">Total: </p>
                      <p class="detail_col  text-right text-text2 font-semibold">{{parseInt(quoteDetails.grandTotal) | amountFormaterWithToFix}}</p>
                    </div>
                    <div class="box lg:col-start-5 2xl:col-start-7 xl:col-start-7  lg:col-span-8  2xl:col-span-6 xl:col-span-6 col-span-12 flex justify-end items-baseline pt-1 text-right" v-if="quoteDetails.isDepositPer">
                      <p class="detail_col mr-4 text-text2 font-semibold"> Deposit ({{ quoteDetails.depositAmt }}%):</p>
                      <p class="detail_col text-text2 font-semibold">{{((quoteDetails.grandTotal * quoteDetails.depositAmt) / 100) | amountFormaterWithToFix}}</p>
                    </div>
                    <div class="box lg:col-start-5 2xl:col-start-7 xl:col-start-7  lg:col-span-8  2xl:col-span-6 xl:col-span-6 col-span-12 flex justify-end items-baseline pt-1 text-right" v-if="!quoteDetails.isDepositPer">
                      <p class="detail_col mr-4 text-text2 font-semibold"> Deposit Amount:</p>
                      <p class="detail_col text-text2 font-semibold">{{(quoteDetails.depositAmt) | amountFormaterWithToFix}}</p>
                    </div>
                  </div>
                  <div class="pt-4" v-if="quoteDetails.customerNotes !== ''">
                    <p class="text-gray4 heading-5">Customer Note:</p>
                    <p class=" text-text2" >{{quoteDetails.customerNotes}}</p>
                  </div>
                  <div class="pt-4" v-if="quoteDetails.internalNotes !== ''">
                    <p class="text-gray4 heading-5">Internal Note:</p>
                    <p class=" text-text2">{{quoteDetails.internalNotes}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     </div>
     <div  v-if="ShowErr && firstLoading">
      <div class="bg-gray-200 w-full px-2 md:px-0 h-screen flex items-center justify-center">
        <div class="bg-white border border-gray-200 flex flex-col items-center justify-center px-4 md:px-8 lg:px-20 py-8 rounded-lg shadow-2xl">
            <p class="text-6xl md:text-7xl lg:text-6xl font-bold tracking-wider text-gray-300"><i class="fa-solid fa-triangle-exclamation fa-fade"></i></p>
            <p class="text-gray-500 mt-3 py-2 border-y-2 text-center">Please Check Your Quotation Link and Try Again Later</p>
        </div>
      </div>
     </div>
  </div>
</template>
<script>
import MyJobApp from '@/api/MyJobApp.js'
import Button from '@/View/components/globalButton.vue'
export default {
  components: {
    Button
  },
  data() {
    return {
      quoteDetails: {},
      OrgDetail: {},
      ShowErr: true,
      firstLoading: false,
      screenWidth: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  mounted() {
    this.myEventHandler()
    this.getInvoiceDetail()
  },
  methods: {
    getInvoiceDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.HashQuotationDetail(
        this.$route.params.hashId,
        response => {
          this.ShowErr = false
          this.firstLoading = true
          this.quoteDetails = response.Data !== null ? response.Data : {}
          document.title = 'Quote: ' + this.quoteDetails.companyName
          this.OrgDetail = response.Data.organizationDetail !== null ? response.Data.organizationDetail : {}
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          if (error.message === 'No Record Found') {
            this.ShowErr = true
            this.firstLoading = true
          }
          this.ShowErr = true
          this.firstLoading = true
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    downloadInvoice () {
      this.$router.push({name: 'DownloadHashQuote', params: {hashId: this.$route.params.hashId}})
    },
    myEventHandler () {
      this.screenWidth = window.innerWidth
    }
  },
};
</script>
<style scoped>
.hash_col {
      min-width: 40px !important;
      width: 80px !important;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      padding-right: 8px;
    }
    .name_col {
      min-width: 40px !important;
      width: 400px !important;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      padding-right: 8px;
    }
    .desc_col {
      min-width: 40px !important;
      width: 450px !important;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      padding-right: 8px;
    }
    .rate_col {
      min-width: 40px !important;
      width: 150px !important;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      padding-right: 8px;
    }
    .qty_col {
      min-width: 40px !important;
      width: 100px !important;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      padding-right: 8px;
    }
    .tax_col {
      min-width: 40px !important;
      width: 100px !important;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      padding-right: 8px;
    }
    .amount_col {
      min-width: 40px !important;
      width: 130px !important;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      padding-right: 8px;
    }
    .detail_col {
      min-width: 40px !important;
      width: 150px !important;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      padding-right: 8px;
    }
    .allTax_col {
      min-width: 40px !important;
      width: 230px !important;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      padding-right: 8px;
    }
    .loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #3498db;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>